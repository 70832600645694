@tailwind base;
@tailwind components;
@tailwind utilities;


body{
  @apply overflow-x-hidden 
}



.animate-inleft{
@apply opacity-100 transition-all duration-500 translate-x-0;
}

.animate-hidden{
  @apply opacity-0 transition-all duration-500  translate-x-negative;
}

.Tab{
  @apply py-4 px-2 flex justify-start w-full items-start text-left text-base font-semibold 
}